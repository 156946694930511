import React, { lazy, Suspense } from "react";
import {
    Outlet,
    Navigate,
    RouterProvider,
    createBrowserRouter,
} from 'react-router-dom';

import Layout from "../core/layout";

import { checkIsAuthenticated, getAuthToken } from "../utils/helpers/intex";
import ProtectedRoute from "./hooks/protectedRoute";
import PageNotFound from "../pages/pagenotfound/pagenotfound";
import Unauthorized from "../pages/unauthorized/unauthorized";


const DashboardPage = lazy(() => import('../pages/dashboard'));
const LoginPage = lazy(() => import('../pages/login'));
const RolePage = lazy(() => import('../pages/role'));
const CompanyPage = lazy(() => import('../pages/company'));
const UserPage = lazy(() => import('../pages/user'));
const AddEditRolePage = lazy(() => import('../pages/role/component/addEditRole'));
const ModulePage = lazy(() => import('../pages/module'));
const AddEditModulePage = lazy(() => import('../pages/module/component/addEditModule'));
const AddEditCompanyPage = lazy(() => import('../pages/company/component/addEditCompany'));
const AddEditUserPage = lazy(() => import('../pages/user/component/addEditUser'));
const RightPage = lazy(() => import('../pages/right'));
const CategoryPage = lazy(() => import('../pages/category'));
const AddEditCategoryPage = lazy(() => import('../pages/category/component/addEditCategory'));
const SubCategoryPage = lazy(() => import('../pages/subcategory'));
const AddEditSubCategoryPage = lazy(() => import('../pages/subcategory/component/addEditSubCategory'));
const StockDetailsPage = lazy(() => import('../pages/stockdetails'));
const AddEditStockDetailsPage = lazy(() => import('../pages/stockdetails/component/addEditStockDetails'));
const ProductPage = lazy(() => import('../pages/product'));
const AddEditProductPage = lazy(() => import('../pages/product/component/addEditProduct'));

const Routes = () => {
    const routes = createBrowserRouter([
        {
            path: '/',
            element: (
                <Layout>
                    <Suspense>
                        <ProtectedRoute Component={<DashboardPage/>}/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated
        },
        {
            path: '/analytics',
            element: (
                <Layout>
                    <Suspense>
                        <ProtectedRoute Component={<AddEditRolePage/>}/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated
        },
        {
            path: '/module',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<ModulePage />} />
                },
                {
                    path: '/module/:mode',
                    element: <ProtectedRoute Component={<AddEditModulePage />} />
                },
                {
                    path: '/module/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditModulePage />} />
                }
            ]
        },
        {
            path: '/role',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<RolePage />} />
                },
                {
                    path: '/role/:mode',
                    element: <ProtectedRoute Component={<AddEditRolePage />} />
                },
                {
                    path: '/role/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditRolePage />} />
                }
            ]
        },
        {
            path: '/company',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<CompanyPage />} />
                },
                {
                    path: '/company/:mode',
                    element: <ProtectedRoute Component={<AddEditCompanyPage />} />
                },
                {
                    path: '/company/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditCompanyPage />} />
                }
            ]
        },
        {
            path: '/user',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<UserPage />} />
                },
                {
                    path: '/user/:mode',
                    element: <ProtectedRoute Component={<AddEditUserPage />} />
                },
                {
                    path: '/user/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditUserPage />} />
                }
            ]
        },
        {
            path: '/right',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<RightPage/>} />
                }
            ]
        },
        {
            path: '/category',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<CategoryPage />} />
                },
                {
                    path: '/category/:mode',
                    element: <ProtectedRoute Component={<AddEditCategoryPage />} />
                },
                {
                    path: '/category/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditCategoryPage />} />
                }
            ]
        },
        {
            path: '/sub-category',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<SubCategoryPage />} />
                },
                {
                    path: '/sub-category/:mode',
                    element: <ProtectedRoute Component={<AddEditSubCategoryPage />} />
                },
                {
                    path: '/sub-category/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditSubCategoryPage />} />
                }
            ]
        },
        {
            path: '/product',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<ProductPage />} />
                },
                {
                    path: '/product/:mode',
                    element: <ProtectedRoute Component={<AddEditProductPage />} />
                },
                {
                    path: '/product/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditProductPage />} />
                }
            ]
        },
        {
            path: '/stock-details',
            element: (
                <Layout>
                    <Suspense>
                        <Outlet/>
                    </Suspense>
                </Layout>
            ),
            loader: checkIsAuthenticated,
            children: [
                {
                    index: true,
                    element: <ProtectedRoute Component={<StockDetailsPage />} />
                },
                {
                    path: '/stock-details/:mode',
                    element: <ProtectedRoute Component={<AddEditStockDetailsPage />} />
                },
                {
                    path: '/stock-details/:mode/:id',
                    element: <ProtectedRoute Component={<AddEditStockDetailsPage />} />
                }
            ]
        },
        {
            path: '/login',
            element: !getAuthToken() ? (
                <Suspense>
                    <LoginPage/>
                </Suspense>
            ): (
                <Navigate to='/' replace={true} />
            )
        },
        {
            path: '404',
            element: <>< PageNotFound /></>

        },
        {
            path: 'not-authorized',
            element: <><Unauthorized /></>
        },
        {
            path: '*',
            element: <Navigate to='/404' replace />
        }
    ]);
    return <RouterProvider router={routes}></RouterProvider>
}

export default Routes;