import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { RootState } from '../../store';

type IProps = {
  Component: any;
};

const ProtectedRoute = ({ Component }: IProps) => {
  const { loginUser } = useSelector((state: RootState) => state.app);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  let mainPath = pathname;

  useEffect(() => {
    const accessModules = loginUser ? loginUser.accessModules : [];
    if (accessModules && accessModules.length > 0) {
      const isAccessible: boolean = accessModules.map((res) => {
          // Check for View
          if (res.moduleId.path === mainPath && res.view) {
            return true;
          } else if (res.moduleId.path === `/${mainPath.split('/')[1]}`) {
            // Check for Add
            if (
              (mainPath.includes('add') || mainPath.includes('create')) &&
              res.add
            ) {
              return true;
              // Check for Edit
            } else if (
              (mainPath.includes('edit') || mainPath.includes('update')) &&
              res.edit
            ) {
              return true;
            } else if (mainPath.includes('reports') && res.view) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        })
        .includes(true);
      if (!isAccessible) {
        navigate('/not-authorized', { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [pathname, mainPath, navigate, loginUser?.accessModules]);

  return Component;
};

export default ProtectedRoute;
