import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./redux/user";
import moduleReducer from "./redux/module";
import roleReducer from "./redux/role";
import categoryReducer from "./redux/category";
import appReducer from "./redux/app";
import subcategory from "./redux/subcategory";
import stockdetails from "./redux/stockdetails";
import product from "./redux/product";


export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer, 
    role: roleReducer,
    module: moduleReducer,
    category: categoryReducer,
    subcategory: subcategory,
    stockdetails: stockdetails,
    product: product,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
