import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { sidebarConfig } from "./sidebar/sidebarConfig";
import Header from "../../components/header/header";
import Sidebar from "../../components/sidebar/sidebar";
import useIsMobileViewHooks from "../../hooks/useIsMobileView";

interface IProps {
  children: React.ReactNode;
}

const Layout: React.FC<IProps> = ({ children }) => {
  const location = useLocation();
  const {
    isMobileView
  } = useIsMobileViewHooks();

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [activeTab, setActiveTab] = useState<string>("");
  const [activeComponent, setActiveComponent] = useState<string>("Dashboard");
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setSidebarOpen(!isMobileView);
  }, [isMobileView]);

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const submenuPath = `/${pathSegments[1]}`;
    const currentLink = sidebarConfig.find(
      (link) => link.path === location.pathname
    );
    const currentSubmenu = sidebarConfig
      .flatMap((link) => link.subMenu)
      .find((submenu) => submenu?.path === submenuPath);
    if (currentLink) {
      setActiveTab(currentLink.name);
    } else if (currentSubmenu) {
      setActiveTab(currentSubmenu.name);
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isMobileView &&
        isSidebarOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setSidebarOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobileView, isSidebarOpen]);

  const handleToggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const handleTabClick = (name: string) => {
    setActiveTab(name);
    if (isMobileView) {
      setSidebarOpen(false);
    }
  };
  return (
    <>
      <div style={{ display: "flex" }}>
        <div ref={sidebarRef}>
          <Sidebar
            links={sidebarConfig}
            onTabClick={handleTabClick}
            isOpen={isSidebarOpen}
            setActiveComponent={setActiveComponent}
          />
        </div>
        <div
          style={{
            flex: 1,
            marginLeft: !isSidebarOpen ? "0.313rem" : isMobileView ? "0.313rem" : "16.25rem",
            // marginLeft: isMobileView
            //   ? "0.313rem"
            //   : isSidebarOpen
            //   ? "16.25rem"
            //   : "0.313rem", //"5rem",
            transition: "margin-left 0.3s",
            // paddingRight: "0.625rem",
            overflowX: "hidden",
          }}
        >
          <Header
            activeTab={activeTab}
            onMenuClick={handleToggleSidebar}
            fullName="John Doe"
          />
          {children}
        </div>
      </div>
    </>
  );
};

export default Layout;
