import DashboardIcon from "../../../assets/images/icons/dashboard";
import MasterIcon from "../../../assets/images/icons/master";
import InventoryIcon from "../../../assets/images/icons/inventory";
import OrderIcon from "../../../assets/images/icons/order";
import ReportIcon from "../../../assets/images/icons/report";
import SettingIcon from "../../../assets/images/icons/setting";
interface SubMenuConfig {
    name: string
    path: string
}

interface SideBarConfig {
    name: string;
    path: string;
    icon: React.ReactNode
    subMenu?: SubMenuConfig[]
}

export const sidebarConfig: SideBarConfig[] = [
    {
        name: 'Dashbaord',
        path: '/',
        icon: <DashboardIcon/>
    },
    {
        name: 'Master',
        path: '',
        icon: <MasterIcon/>,
        subMenu: [
            {
                name: 'Role',
                path: '/role'
            },
            {
                name: 'Module',
                path: '/module',
            },
            {
                name: 'Company',
                path: '/company',
            },
            {
                name: 'Rights',
                path: '/right'
            },
            {
                name: 'User',
                path: '/user',
            },
            {
                name: 'Category',
                path: '/category',
            },
            {
                name: 'Sub Category',
                path: '/sub-category',
            },
            {
                name: 'Product',
                path: '/product',
            },
            {
                name: 'Stock Details',
                path: '/stock-details',
            }
        ]
    },
    // {
    //     name: 'Analytics',
    //     path: '/analytics',
    //     icon: <AnalyticsIcon/>
    // },
    {
        name: 'Inventory',
        path: '/inventory',
        icon: <InventoryIcon/>
    },
    {
        name: 'Order',
        path: '/order',
        icon: <OrderIcon/>
    },
    {
        name: 'Report',
        path: '/report',
        icon: <ReportIcon/>
    },
    {
        name: 'Setting',
        path: '/setting',
        icon: <SettingIcon/>
    },
    // {
    //     name: 'Logout',
    //     path: '/logout',
    //     icon: <LogoutIcon/>
    // },
];

